<template>
    <div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'Operational',

    data() {
        return {};
    },
    computed: {
        // ...mapGetters('auth', ['loggedIn'])
    },
    methods: {}
};
</script>

<style></style>
